;(function () {
  const wrapper = document.querySelector('#font-tester')
  if (!wrapper) {
    return
  }

  const fonts = {
    Title: [
      'Cardo',
      'Sedan',
      'Playfair Display',
      'DM Serif Display',
      'Neuton',
      'Prata',
      'Mate',
      'Forum',
      'Times New Roman',
      'Georgia',
    ].sort(),
    Content: ['Segoe UI', 'Titillium Web', 'Dosis', 'Urbanist', 'Noto Sans Display', 'Sarabun'].sort(),
  }

  const controlsContainer = document.createElement('div')
  controlsContainer.id = '#font-tester--controls'
  controlsContainer.style.setProperty('margin-bottom', '2rem')
  controlsContainer.style.setProperty('border', '1px solid black')
  controlsContainer.style.setProperty('border-radius', '10px')
  controlsContainer.style.setProperty('padding', '1rem')
  controlsContainer.style.setProperty('display', 'flex')
  controlsContainer.style.setProperty('flex-direction', 'column')
  controlsContainer.style.setProperty('align-items', 'flex-start')
  controlsContainer.style.setProperty('justify-content', 'flex-start')
  controlsContainer.style.setProperty('gap', '2rem')
  wrapper.appendChild(controlsContainer)

  const buttons = new Map<string, HTMLButtonElement[]>()
  for (const type in fonts) {
    const container = document.createElement('div')
    container.id = `#font-tester--controls__${type}`
    controlsContainer.appendChild(container)

    const label = document.createElement('h2')
    label.innerText = type
    label.style.setProperty('font-size', '1.5rem')
    container.appendChild(label)

    const buttonContainer = document.createElement('div')
    buttonContainer.id = `#font-tester--buttons__${type}`
    buttonContainer.style.setProperty('display', 'flex')
    buttonContainer.style.setProperty('flex-flow', 'row wrap')
    buttonContainer.style.setProperty('align-items', 'center')
    buttonContainer.style.setProperty('justify-content', 'flex-start')
    buttonContainer.style.setProperty('gap', '1rem')
    container.appendChild(buttonContainer)

    for (const font of fonts[type]) {
      const button = document.createElement('button')
      button.innerText = font
      button.style.setProperty('font-family', font)
      button.style.setProperty('height', '36px')
      button.style.setProperty('display', 'flex')
      button.style.setProperty('align-items', 'center')
      button.style.setProperty('justify-content', 'center')
      button.dataset.target = type
      button.dataset.font = font

      buttons.get(type)?.push(button) || buttons.set(type, [button])
      buttonContainer.appendChild(button)
    }
  }

  const displayWrapper = document.createElement('div')
  displayWrapper.id = 'font-tester--display'
  displayWrapper.style.setProperty('padding', '3rem')
  displayWrapper.style.setProperty('margin-bottom', '2rem')
  displayWrapper.style.setProperty('border', '1px solid black')
  displayWrapper.style.setProperty('border-radius', '10px')
  wrapper.appendChild(displayWrapper)

  const title = document.createElement('h1')
  title.innerText = 'Pensacola’s Only Full-Line Music Store'
  title.classList.add('font-tester--Title')
  displayWrapper.appendChild(title)

  const content = document.createElement('p')
  content.innerText =
    'Sometimes we use the phrase “full-line music store” to describe what we do. Essentially, that means we sell products across all musical instrument categories as well as pro audio installations or rentals, music lessons, and school band instrument rentals. We also service and repair instruments from piccolos to pianos. PLUS, we teach lessons in our school of music for everything we sell. We’ll even buy your used instruments.'
  content.classList.add('font-tester--Content')
  displayWrapper.appendChild(content)

  controlsContainer.addEventListener('click', e => {
    const eventTarget = e.target
    if (!(eventTarget instanceof HTMLButtonElement)) {
      return
    }
    const type = eventTarget.dataset.target
    const font = eventTarget.dataset.font
    if (!type || !font) {
      return
    }

    buttons.get(type)?.forEach(el => {
      el.classList.remove('is-color-primary')
    })
    eventTarget.classList.add('is-color-primary')
    eventTarget.blur()

    const targets = wrapper.querySelectorAll<HTMLElement>(`.font-tester--${type}`)
    targets.forEach(target => {
      target.style.setProperty('font-family', font)
    })
  })

  buttons.forEach(buttons => {
    buttons[0]?.click()
  })
})()
